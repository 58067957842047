<template>
  <div class="flex h-screen">
  <div class="flex self-center p-4 flex-col m-auto md:w-10/12 lg:w-2/5 elevation-1 justify-items-center align-items-center">
    <img class="mx-auto w-2/4 m-4" src="@/assets/BLM_FISTING_DOLLAR.svg" alt="Virtue.ForSale Logo">
    <h1 class="text-gray-100 text-6xl uppercase text-center">Virtue For Sale</h1>
    <h2 class="text-gray-200 text-4xl text-center">Under Construction</h2>
    <br>
    <p>
Virtue for sale is a new crowd-sourced platform for ranking the <span class="italic">"virtues"</span> of today's businesses and organizations.</p>
    <p>
Many businesses jump on the latest political trends to get a quick buck. Our platform is here to record those that decide to virtue signal and rank them. You should know exactly what a business <span class="italic">"believes"</span> in before you make a purchase from them.
    </p>
    <Timer />
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Timer from '@/components/Timer.vue';

export default defineComponent({
  name: 'App',
  components: {
    Timer
  }
});
</script>

<style lang="scss">
p {
  @apply text-gray-200;
  @apply leading-tight;
  @apply pb-2;
}
</style>
