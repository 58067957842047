<template>
  <div v-if="show" class="border flex flex-col center-items m-4 elevation-2">
    <span class="text-gray-300 text-2xl text-center">Alpha ETA:</span>
    <div class="text-gray-300 grid grid-rows-2 text-center my-4">
      <div class="grid grid-cols-4 text-5xl row-span-1">
        <div class="">
          {{ days }}
        </div>
        <div class="">
          {{ hours }}
        </div>
        <div class="">
          {{ minutes }}
        </div>
        <div class="">
          {{ seconds }}
        </div>
      </div>
      <div class="grid grid-cols-4 text-xl row-span-1">
        <div class="">
          Days
        </div>
        <div class="">
          Hours
        </div>
        <div class="">
          Minutes
        </div>
        <div class="">
          Seconds
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

export default defineComponent({
  name: 'Timer',
  data() {
    return {
      estimate: dayjs('2020-11-09T17:00:00-07:00'),
      duration: dayjs.duration(100)
    }
  },
  computed: {
    days() {
      const days = this.duration.days();
      return (days <=0 ? 0 : days)
    },
    hours() {
      const hours = this.duration.hours();
      return (hours <=0 ? 0 : hours)
    },
    minutes() {
      const minutes = this.duration.minutes();
      return (minutes <=0 ? 0 : minutes)
    },
    seconds() {
      const seconds = this.duration.seconds();
      return (seconds <=0 ? 0 : seconds)
    },
    show() {
      const asSeconds = this.duration.asSeconds()
      return (asSeconds <=0 ? false: true)
    }
  },
  mounted() {
    this.duration = dayjs.duration(this.estimate.diff(dayjs()));
    setInterval(() => {
      this.duration = dayjs.duration(this.estimate.diff(dayjs()))
    }, 1000);
  }
});
</script>

<style lang="scss">

</style>
